import React from 'react';
import ReactModal from 'react-modal';
import Image from 'gatsby-image'
import AspectRatio from 'react-aspect-ratio';
import styled, { createGlobalStyle } from 'styled-components';

ReactModal.setAppElement('#___gatsby');

const GlobalStyle = createGlobalStyle`
.ReactModal__Content {
  width:90%;
  @media screen and (min-width:900px) {
    width:66%;
  }
}

`

const VideoImageWrapper = styled.div`
  &:hover {
    .img--cover {
      filter: brightness(92%);
    }
    .icon {
      transform:scale(1.05);
    }
  }
`

export default class VideoModal extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      showModal: false
    };

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleOpenModal () {
    this.setState({...this.state, showModal: true});
  }

  handleCloseModal () {
    this.setState({...this.state, showModal: false});
  }

  render () {
    return (
      <div className="md:my-16 my-12">
        <GlobalStyle />
        <section className="video">
          <div className="o-wrapper">
            <AspectRatio
              ratio={this.props.aspectRatio || '16/9'}
              className="mx-auto bg-grey-lighter"
              style={{maxWidth:'996px'}}
            >
              <VideoImageWrapper className="absolute inset-0 cursor-pointer" onClick={this.handleOpenModal}>
                {this.props.poster && this.props.poster.localFile &&
                  <Image fluid={this.props.poster.localFile.childImageSharp.fluid} className="h-full w-full img--cover transition-all duration-200 ease-in-out" draggable={false} />
                }

                <div className="absolute inset-0 flex justify-center items-center">
                  <img className="icon transition-transform duration-200 ease-in-out" src={require('../images/icons/play.svg')} alt="Play"/>
                </div>
              </VideoImageWrapper>
            </AspectRatio>
          </div>
        </section>

        <ReactModal
          isOpen={this.state.showModal}
          shouldCloseOnOverlayClick={true}
          onRequestClose={this.handleCloseModal}
          style={{
            overlay: {
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.9)',
              zIndex:'51'
            },
            content: {
              position: 'absolute',
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              border: 'none',
              background: 'transparent',
              overflow: 'auto',
              WebkitOverflowScrolling: 'touch',
              borderRadius: '4px',
              outline: 'none',
              padding: '0',
              display:'flex',
              flexDirection:'column',
              justifyContent:'space-between',
              transform: 'translate(-50%, -50%)',
              // width:'66%',
              maxHeight:'90vh',
              minWidth:'360px'
            }
          }}
        >

          <button onClick={this.handleCloseModal} className="text-right mb-4 ml-auto mr-0 transition transform hover:scale-90 duration-150 outline-none">
            <img src={require('../images/icons/close.svg')} alt="Close" style={{ maxWidth:'30px', width:'100%'}} />
          </button>

          <main className="flex-auto flex flex-col justify-center">
            {this.props.embedType === 'iframe' &&
              <AspectRatio ratio="16/9">
                <iframe title="Video" src={this.props.video} frameBorder="0"></iframe>
              </AspectRatio>
            }

            {this.props.embedType === 'html5' &&
            // eslint-disable-next-line
              <video src={this.props.video}></video>
            }
          </main>
        </ReactModal>
      </div>
    );
  }
}