import React, { Component } from 'react';

class TwoColStats extends Component {
  render() {
    return (
      <section className="md:my-32 sm:my-20 my-16 xl:py-32 sm:py-24 py-16 bg-tertiary text-center lg:text-left">
        <div className="o-wrapper">
          <div className="grid lg:grid-cols-2 gap-6">
            <aside className="mb-6 md:mb-8 lg:mb-0">
              <div className="max-w-md mx-auto lg:ml-0">
                <h2 className="u-h2 mb-6">{this.props.heading}</h2>
                <p dangerouslySetInnerHTML={{ __html:this.props.content }} />
              </div>
            </aside>
            <main className="grid grid-cols-2 gap-3">
              {this.props.items.filter(itm => itm.heading && itm.content).map((item, i)=>
                <div key={this.props.heading+'item'+i} className="bg-black text-white p-6 flex flex-col justify-center">
                  <h5 className="u-h2 mb-3">{item.heading}</h5>
                  <div className="font-medium leading-snug" dangerouslySetInnerHTML={{ __html:item.content }} />
                </div>
              )}
            </main>
          </div>
        </div>
      </section>
    );
  }
}

export default TwoColStats;