import React, { Component } from 'react';
import Image from 'gatsby-image';
import { imageExists } from '../utils';

// import PropTypes from 'prop-types';

class TwoColServicesPanel extends Component {
  render() {

    return (
      <section className="md:my-32 sm:my-20 my-16">
        <div className="o-wrapper">
          <div className={`p-4 sm:p-6 lg:p-8 bg-${this.props.colour || 'blue-light'}`}>
            <div className="xl:p-12 md:p-10  bg-white">
              <div className="flex flex-col md:flex-row items-center -ml-16">
                <div className="pl-16 md:w-7/12 order-1 md:order-0">
                  <div className="md:max-w-md p-6 sm:p-8 md:p-0">
                    <h3 className="u-h3 mb-4">{this.props.heading}</h3>
                    <p dangerouslySetInnerHTML={{ __html: this.props.content }} />
                  </div>
                </div>
                <div className="pl-16 md:w-5/12 w-full">
                  {imageExists(this.props.image) &&
                    <Image className="h-full mr-0 ml-auto two-col-services-image" fluid={this.props.image.localFile.childImageSharp.fluid} draggable={false} />
                  }
                </div>
              </div>
            </div>
          </div>
          {this.props.items && this.props.items.length &&
            <div className="grid md:grid-cols-3 sm:grid-cols-2 gap-8 md:mt-16 mt-10">
              {this.props.items.map((item, i) =>
                <div key={this.props.heading+item.heading+i} className={`pb-8 border-b-2 border-${this.props.colour}`}>
                  <h4 className="u-h5 mb-4">{item.heading}</h4>
                  <p>{item.content}</p>
                </div>
              )}
            </div>
          }
        </div>
      </section>

    );
  }
}

export default TwoColServicesPanel;