import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import Image from 'gatsby-image';
import Layout from "../components/layout"
import SEO from "../components/seo"
import TwoColServicesPanel from '../components/two-col-services-panel';
import TwoColStats from '../components/two-col-stats';
import styled from 'styled-components';
import VideoModal from '../components/video-modal';
import Form from "../components/form"
// import Anime from 'react-anime';

const ImageWrapper = styled.div`
  @media screen and (max-width:768px) {
    overflow: hidden;
    position:relative;
    width:100%;
    &:after {
      content:'';
      display:block;
      padding-bottom:62%;
    }

    > div {
      position:absolute;
      top:0;
      left:0;
      right:0;
      bottom:0;
      display:flex;
      flex-direction:column;
      justify-content:center;

    }
  }
`

const VideoModalWrapper = styled.section`
height:100%;
.my-12 {margin-top:0!important;margin-bottom:0!important;}

.o-wrapper {padding-left:0;padding-right:0;}
.o-wrapper > .mx-auto {max-width:100%!important}

section.video,
.o-wrapper,
.o-wrapper > .mx-auto,
.my-12 {height:100%;}
`

const ServicesPage = ({ data }) => {
  const { hero, video_url, intro, accordion, journey, what_we_repair, delivered, claims, logistics, infrastructure, values, cta } = data.page.acf;
  const { contact_form } = data.contact.acf;
  const [ openAccItem, setOpenAccItem ] = useState(0);
  const [ formOpen, setFormOpen ] = useState(false);

  const toggleForm = ()=>{
    setFormOpen(!formOpen);
  }

  return (
    <Layout>
      <SEO yoast={data.page.yoast_meta} />

      <Form
        acf={contact_form}
        isOpen={formOpen}
        toggleForm={toggleForm}
      />

      {/* Hero */}
      <section className="md:pt-10">
        <div className="o-wrapper">
          <div className="flex flex-wrap items-stretch -ml-6">
            <div className="xl:w-5/12 md:w-6/12 w-full pl-6 flex flex-col justify-center">
              <div className="md:max-w-sm lg:py-20 md:py-16 py-12">
                <h1 className="u-h1 fade-in-up">{hero.hero.heading}</h1>
                <p className="text-20px mt-6 mb-8 fade-in-up ani-delay-200">{hero.hero.content}</p>
                {hero.hero.buttons.map(({button}, cnt) =>
                  <Link key={'service-hero'+button.title} to={button.url} className="w-full sm:w-auto inline-block c-btn bg-black text-white hover:bg-green-light hover:text-black fade-in-up ani-delay-200" dangerouslySetInnerHTML={{ __html: button.title }} />
                )}
              </div>
            </div>
            <div className="xl:w-7/12 md:w-6/12 w-full pl-6">
              <VideoModalWrapper className="bg-tertiary breakout-wrapper-until-md">
                <VideoModal
                  video={video_url}
                  embedType={video_url.includes('youtu') ? 'iframe' : 'html5'}
                  poster={hero.hero.image}
                />
              </VideoModalWrapper>
            </div>
          </div>
        </div>
      </section>


      {/* Logos */}
      <section className="logos my-12 md:my-16">
        <div className="o-wrapper">
          <h2 className="font-semibold text-center mb-8">{intro.heading}</h2>
          <div className="grid md:grid-cols-5 grid-cols-4 md:gap-10 gap-6 items-center justify-center pb-3 md:pb-4">
            {intro.logos.map((l, i) =>
              <img key={'service-logo-'+l+i} src={l} alt="Logo" className={`mx-auto transform scale-75 sm:scale-90 md:scale-100 ${i >= 4 ? 'hidden md:block': ''}`} />
            )}
          </div>
        </div>
      </section>


      {/* Accordion */}
      <section className="md:my-32 my-20 md:mt-20 mt-12">
        <div className="o-wrapper">
          <div className="grid md:grid-cols-2 items-center gap-8">

            <ImageWrapper className="breakout-wrapper-until-md h-full">
              <div>
                <Image fluid={accordion.image.localFile.childImageSharp.fluid} draggable={false} className="h-full"/>
              </div>
            </ImageWrapper>

            <div>
              <div className="md:max-w-md mx-auto">
                {accordion.accordion.map((item, i) =>
                  <div
                    key={`services-acc-item${i}`}
                    className={`accordion-item mb-4`}
                  >
                    {/* eslint-disable-next-line */}
                    <header
                      className="cursor-pointer text-h5 font-semibold flex items-center select-none"
                      onClick={() => setOpenAccItem(openAccItem === i ? false : i)}
                    >
                      <img
                        src={require('../images/circle-arrow.svg')}
                        className={`mr-3 transform ${openAccItem === i ? '' : 'rotate-180'}`}
                        alt="Arrow"
                      />
                      <div>{item.heading}</div>
                    </header>

                    <div className={`overflow-hidden transition-all duration-500 ease-in-out ${ openAccItem === i  ? 'max-h-700' : 'max-h-0'}`}>
                      <main className="mt-4 mb-6">
                        {item.content}
                      </main>
                    </div>

                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <TwoColServicesPanel
        heading={journey.heading}
        content={journey.content}
        image={journey.image}
        colour="tan"
      />


      <section className="md:my-32 my-20">
        <div className="o-wrapper">
          <header className="md:max-w-lg lg:mb-16 md:mb-12 mb-8">
            <h2 className="u-h2 mb-6">{what_we_repair.heading}</h2>
            <p>{what_we_repair.content}</p>
          </header>

          <main className="grid md:grid-cols-2 gap-8">
            {what_we_repair.items.map((item, i)=>
              <div key={`wwr_item_${i}`} className="md:pb-12 pb-6 border-b-2 border-tan md:pr-4">
                <h3 className="u-h5 mb-4">{item.heading}</h3>
                <p dangerouslySetInnerHTML={{ __html:item.content }}/>
              </div>
            )}
          </main>

        </div>
      </section>

      <TwoColStats
        heading={delivered.heading}
        content={delivered.content}
        items={delivered.items}
      />


      <TwoColServicesPanel
        heading={claims.heading}
        content={claims.content}
        image={claims.image}
        items={claims.items}
        colour="green-light"
        />

      <TwoColServicesPanel
        heading={logistics.heading}
        content={logistics.content}
        image={logistics.image}
        items={logistics.items}
        colour="blue-light"
      />

      <TwoColStats
        heading={infrastructure.heading}
        content={infrastructure.content}
        items={infrastructure.items}
      />


      <section className="md:my-32 sm:my-20 my-16">
        <div className="o-wrapper">

          <header className="md:max-w-lg  lg:mb-16 md:mb-12 mb-8">
            <h2 className="u-h2 mb-6">{values.heading}</h2>
          </header>

          <main className="grid md:grid-cols-2  row-gap-12 gap-8">
            {values.items.map((item, i)=>
              <div key={`value_item_${i}`} className="">
                <Image fluid={item.image.localFile.childImageSharp.fluid} draggable={false} className="about-image-pad" />
                <h3 className="sm:text-32px text-2xl leading-snug font-semibold mb-4 mt-6 sm:mt-8 sm:mb-5">{item.heading}</h3>
                <p dangerouslySetInnerHTML={{ __html:item.content }}/>
              </div>
            )}
          </main>


        </div>
      </section>

      {/* CTA */}
      <section className="md:my-28 sm:my-20 my-16">
        <div className="o-wrapper">
          <div className="bg-blue-light p-10 sm:p-16 lg:p-20">
            <div className="flex flex-wrap items-center justify-center text-center md:text-left md:justify-start -ml-10">
              <div className="md:w-7/12 pl-10">
                <h3 className="u-h3 mb-4">{cta.heading}</h3>
                <h5 dangerouslySetInnerHTML={{__html:cta.content}} />
              </div>
              <div className="md:w-5/12 pl-10 flex flex-col justify-end items-end mt-8 md:mt-0">
                <button className="c-btn bg-black text-white hover:bg-tertiary hover:text-black transition duration-150" onClick={()=>setFormOpen(true)}>{cta.button.title}</button>
              </div>
            </div>
          </div>
        </div>
      </section>

    </Layout>
  )
}

export default ServicesPage


export const query = graphql`
query Services {
  contact: wordpressPage(template:{eq:"contact.php"}) {
    id
    acf {
      contact_form {
        heading
        content
        confirmation {
          heading
          content
        }
      }
    }
  }

  page: wordpressPage(template: {eq: "services.php"}) {
    ...Yoast
    acf {
      hero {
        hero {
          heading
          content
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth:1000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          buttons {
            button {
              url
              title
              target
            }
          }
        }
      }
      video_url

      intro {
        heading
        logos: image
      }

      accordion {
        image {
          localFile {
            childImageSharp {
              fluid(maxWidth:600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        accordion {
          heading
          content
        }
      }

      journey {
        heading
        content
        image {
          localFile {
            childImageSharp {
              fluid(maxWidth:600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }

      what_we_repair {
        heading
        content
        items {
          heading
          content
        }
        #logos
      }

      delivered {
        heading
        content
        items {
          heading
          content
        }
      }

      claims {
        heading
        content
        items {
          heading
          content
        }
        image {
          localFile {
            childImageSharp {
              fluid(maxWidth:600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }

      logistics {
        heading
        content
        image {
          localFile {
            childImageSharp {
              fluid(maxWidth:600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        items {
          heading
          content
        }
      }

      infrastructure {
        heading
        content
        items {
          heading
          content
        }
      }

      values {
        heading
        items {
          heading
          content
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth:600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }

      cta {
        heading
        content
        button {
          title
          url
          target
        }
      }


    }
  }
}

`